
import Vue from "vue";
import NavBar from "./components/NavBar.vue";
import { OpenAPI } from "./commons/client";
import { ServiceSchema, ServicesService } from "./commons/client";

export default Vue.extend({
  name: "App",

  components: { NavBar },
  data: () => ({
    //
  }),
  methods: {
    async getServices(): Promise<ServiceSchema[]> {
      try {
        return await ServicesService.serviceListV1ServicesGet();
      } catch (err) {
        return [];
      }
    },
  },
  created() {
    this.getServices().then((res) => {
      this.$store.commit("setServices", res);
    });
    let token = this.$store.state.token || "";
    OpenAPI.TOKEN = token;
  },
});
