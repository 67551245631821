
import { defineComponent } from "vue";
import {
  DossierSchema,
  DossiersService,
  DossierStateEnum,
  DossierOutcomeEnum,
  ServiceSchema,
} from "@/commons/client";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";

export default defineComponent({
  name: "ClosedPractices",

  components: {
    Alerter,
  },
  data: () => ({
    showWarning: true,
    alerts: [] as Alert[],
    startDateOpen: false,
    endDateOpen: false,
    dossierSearch: {
      start_date: "",
      end_date: "",
      object_type: "",
      service_object_type: "",
      scope: "",
      description: "",
      outcome: "",
    },
    dossierScopes: [
      { text: "Porto La Spezia", value: "spezia" },
      { text: "Porto Marina di Carrara", value: "carrara" },
      { text: "Non Applicabile", value: "" },
    ],
    queryData: {
      perPage: 5,
      currentPage: 1,
      organization_id: 1,
    },
    loading: false,
    search: "",
    headers: [
      {
        text: "Data Inizio",
        align: "start",
        filterable: false,
        value: "start_date",
      },
      { text: "Procedimento", value: "object_type" },
      { text: "Tipo", value: "service_object_type" },
      {
        text: "Stato",
        sortable: false,
        filterable: false,
        value: "state",
      },
      { text: "Ambito", value: "scope" },
      {
        text: "Descrizione",
        sortable: false,
        value: "description",
      },
      {
        text: "Link",
        sortable: false,
        filterable: false,
        value: "service_object_link",
      },
      {
        text: "Data fine",
        filterable: false,
        value: "end_date",
      },
      {
        text: "Risultato",
        value: "outcome",
      },
    ],
    states: [
      { text: "Successo", value: DossierOutcomeEnum.SUCCESS },
      { text: "Fallimento", value: DossierOutcomeEnum.FAILURE },
    ],
    closedPractices: [] as DossierSchema[],
    filteredPractices: [] as DossierSchema[],
  }),
  computed: {
    maxCardWidth(): number {
      /*************************************
       * Return tthe right card length based on
       * current breakpoint.
       *************************************/
      switch (this.$vuetify.breakpoint.name) {
        case "xs" || "sm":
          return 400;
      }
      return 550;
    },
    limit(): number {
      return this.queryData.perPage * 20;
    },
    offset(): number {
      return (this.queryData.currentPage - 1) * this.limit;
    },
    rules(): any[] {
      return this.$store.state.genericRules;
    },
    services(): ServiceSchema[] {
      return this.$store.state.services;
    },
    activeServices(): ServiceSchema[] {
      return this.services.filter((service) => service.blacklisted !== true);
    },
  },
  methods: {
    handleEventShowWarning() {
      this.showWarning = true;
    },
    formattedDate: function (DateField: string): string {
      let formattedDate = "";
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "numeric",
      };
      if (DateField) {
        formattedDate = new Date(DateField).toLocaleDateString(
          "it-IT",
          options
        );
      }
      return formattedDate;
    },
    getItemOutcome(outcome: DossierOutcomeEnum): string {
      switch (outcome) {
        case DossierOutcomeEnum.SUCCESS:
          return "Successo";
        case DossierOutcomeEnum.FAILURE:
          return "Fallimento";
        default:
          return "Non definito";
      }
    },
    async getPractices(): Promise<DossierSchema[]> {
      try {
        return await DossiersService.dossierListV1DossiersGet(
          this.offset,
          this.limit
        );
      } catch (error) {
        this.alerts.push({
          type: "error",
          text: "Errore nel recupero dei dati",
        });
        return [];
      }
    },
    clearFilters() {
      this.dossierSearch = {
        start_date: "",
        end_date: "",
        object_type: "",
        service_object_type: "",
        scope: "",
        description: "",
        outcome: "",
      };
      this.setClosedPractices();
    },
    async getClosedPractices(): Promise<DossierSchema[]> {
      let practices = await this.getPractices();
      return practices.filter((practice) => {
        return practice.state == DossierStateEnum.CLOSED;
      });
    },
    getFilteredItems(): DossierSchema[] {
      let items = this.closedPractices;
      if (this.dossierSearch.object_type) {
        items = items.filter((item) => {
          let regexp = new RegExp(this.dossierSearch.object_type, "i");
          return item.object_type.match(regexp);
        });
      }
      if (this.dossierSearch.service_object_type) {
        items = items.filter((item) => {
          let regexp = new RegExp(this.dossierSearch.service_object_type, "i");
          return item.service_object_type.match(regexp);
        });
      }
      if (this.dossierSearch.scope) {
        items = items.filter((item) => {
          let regexp = new RegExp(this.dossierSearch.scope, "i");
          return item.scope?.match(regexp);
        });
      }
      if (this.dossierSearch.description) {
        items = items.filter((item) => {
          let regexp = new RegExp(this.dossierSearch.description, "i");
          return item.description?.match(regexp);
        });
      }
      if (this.dossierSearch.outcome) {
        items = items.filter((item) => {
          return item.outcome == this.dossierSearch.outcome;
        });
      }
      if (this.dossierSearch.start_date) {
        items = items.filter((item) => {
          return (
            new Date(item.start_date).getTime() >=
            new Date(this.dossierSearch.start_date).getTime()
          );
        });
      }
      if (this.dossierSearch.end_date) {
        items = items.filter((item) => {
          if (item.end_date == null) {
            return true;
          }
          return (
            new Date(item.end_date).getTime() <=
            new Date(this.dossierSearch.end_date).getTime()
          );
        });
      }
      return items;
    },
    setClosedPractices(): void {
      this.filteredPractices = this.getFilteredItems();
      if (this.filteredPractices.length == 0 && this.showWarning) {
        this.alerts.push({
          type: "warning",
          text: "Nessuna pratica trovata con i criteri di ricerca impostati",
        });
        this.showWarning = false;
      }
    },
    onSearchPressed(): void {
      this.alerts = [];
      this.showWarning = true;
      this.setClosedPractices();
    },
  },
  async mounted() {
    this.closedPractices = this.filteredPractices =
      await this.getClosedPractices();
  },
});
