
import Vue from "vue";
import { OpenAPI } from "@/commons/client";
export default Vue.extend({
  name: "RedirectPage",
  data: () => ({}),

  created() {
    var vat = this.$route.query.vat;
    var token =
      typeof this.$route.query.token === "string"
        ? this.$route.query.token
        : "";
    OpenAPI.TOKEN = token;
    this.$store.commit("setVat", vat);
    this.$store.commit("setToken", token);
    this.$router.push("/");
  },
});
