/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DossierOutcomeEnum {
    SUCCESS = 'success',
    FAILURE = 'failure',
}
