
import Vue from "vue";
import { Alert } from "@/commons/types";

export default Vue.extend({
  name: "Alerter",  // eslint-disable-line
  props: {
    alerts: {
      type: Array as () => Alert[],
      required: true,
    },
  },
  methods: {
    closeAlert(index: number): void {
      this.alerts.splice(index, 1); // eslint-disable-line
      this.$emit("eventShowWarning");
    },
  },
});
