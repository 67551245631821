
import Vue from "vue";
import NavigationMenu from "./NavigationMenu.vue";
import { OpenAPI } from "@/commons/client";

export default Vue.extend({
  name: "NavBar",

  data: () => ({
    showDrawer: true,
  }),

  computed: {
    isAuthenticated() {
      return !this.$store.getters.isAnonymous;
    },
  },

  components: {
    NavigationMenu,
  },

  methods: {
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    logout() {
      this.$store.commit("setVat", "");
      this.$store.commit("setToken", "");
      OpenAPI.TOKEN = undefined;
    },
  },
});
