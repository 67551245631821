/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DossierStateEnum {
    OPEN = 'open',
    CLOSED = 'closed',
}
