import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { ServiceSchema } from "@/commons/client/";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    vat: "",
    token: "",
    genericRules: {
      /*************************************
       * Set of rules for field validation.
       * Provide min and max length and
       * required validation.
       *************************************/
      allowAny: (value: string) => true,
      required: (value: string) => !!value || "Obbligatorio!",
      minLen4: (value: string) => value.length >= 4 || "Targa troppo corta!",
      maxLen24: (value: string) => value.length <= 24 || "Targa troppo lunga!",
      maxLen56: (value: string) => value.length <= 56 || "Targa troppo lunga!",
      maxLen80: (value: string) => value.length <= 80 || "Targa troppo lunga!",
      isEmail: (value: string) =>
        /^.+@.+\..+/.test(value) || "Email non valida!",
    },
    services: [] as ServiceSchema[],
  },
  getters: {
    hasVat: (state): boolean => {
      return state.vat != "" && state.vat != null && state.vat != undefined;
    },
    isAnonymous: (state): boolean => {
      return (
        state.token === "" || state.token === null || state.token === undefined
      );
    },
  },
  mutations: {
    setVat(state, vat: string): void {
      state.vat = vat;
    },
    setToken(state, token: string): void {
      state.token = token;
    },
    setServices(state, services: ServiceSchema[]): void {
      state.services = services;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
