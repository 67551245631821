/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ServiceCreateSchema } from '../models/ServiceCreateSchema';
import type { ServiceSchema } from '../models/ServiceSchema';
import type { ServiceUpdateSchema } from '../models/ServiceUpdateSchema';
import type { TaskResultSchema } from '../models/TaskResultSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ServicesService {

    /**
     * Service List
     * @param skip
     * @param limit
     * @returns ServiceSchema Successful Response
     * @throws ApiError
     */
    public static serviceListV1ServicesGet(
        skip?: number,
        limit: number = 100,
    ): CancelablePromise<Array<ServiceSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/services/',
            query: {
                'skip': skip,
                'limit': limit,
            },
            errors: {
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Service Create
     * @param requestBody
     * @returns ServiceSchema Successful Response
     * @throws ApiError
     */
    public static serviceCreateV1ServicesPost(
        requestBody: ServiceCreateSchema,
    ): CancelablePromise<ServiceSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/services/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Service Get
     * @param serviceId
     * @returns ServiceSchema Successful Response
     * @throws ApiError
     */
    public static serviceGetV1ServicesServiceIdGet(
        serviceId: string,
    ): CancelablePromise<ServiceSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/services/{service_id}/',
            path: {
                'service_id': serviceId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Service Update
     * @param serviceId
     * @param requestBody
     * @returns ServiceSchema Successful Response
     * @throws ApiError
     */
    public static serviceUpdateV1ServicesServiceIdPut(
        serviceId: string,
        requestBody: ServiceUpdateSchema,
    ): CancelablePromise<ServiceSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/services/{service_id}/',
            path: {
                'service_id': serviceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Service Destroy
     * @param serviceId
     * @returns void
     * @throws ApiError
     */
    public static serviceDestroyV1ServicesServiceIdDelete(
        serviceId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/services/{service_id}/',
            path: {
                'service_id': serviceId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Service Blacklist
     * @param serviceId
     * @returns TaskResultSchema Successful Response
     * @throws ApiError
     */
    public static serviceBlacklistV1ServicesServiceIdPatch(
        serviceId: string,
    ): CancelablePromise<TaskResultSchema> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/services/{service_id}/',
            path: {
                'service_id': serviceId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Service Get Current
     * @returns ServiceSchema Successful Response
     * @throws ApiError
     */
    public static serviceGetCurrentV1ServicesCurrentGet(): CancelablePromise<ServiceSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/services/current/',
            errors: {
                403: `Forbidden`,
            },
        });
    }

}
