import Vue from "vue";
import VueRouter, { RouteConfig, Route } from "vue-router";
import store from "@/store"; //eslint-disable-line
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView.vue";
import ClosedPracticesView from "../views/ClosePracticeView.vue";
import OpenPracticeView from "../views/OpenPracticeView.vue"; //eslint-disable-line
import RedirectPageView from "../views/RedirectPageView.vue";
import OauthMissingVatView from "../views/OauthMissingVatView.vue";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  // {
  //   path: "/openpractice",
  //   name: "openpractice",
  //   component: OpenPracticeView,
  // },
  {
    path: "/closedpractices",
    name: "closedpractices",
    component: ClosedPracticesView,
  },
  {
    path: "/oauth2/authorized/",
    name: "redirect",
    component: RedirectPageView,
  },
  {
    path: "/oauth2/authorized/missing/",
    name: "oauth2MissingVat",
    component: OauthMissingVatView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next: (...args: any[]) => void) => { //eslint-disable-line
  if (
    to.name !== "login" &&
    to.name !== "home" &&
    to.name !== "redirect" &&
    to.name !== "oauth2MissingVat" &&
    store.getters.isAnonymous
  ) {
    next({ name: "login" });
  } else {
    next();
  }
});

export default router;
