/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DossierCreateSchema } from '../models/DossierCreateSchema';
import type { DossierSchema } from '../models/DossierSchema';
import type { DossierStateEnum } from '../models/DossierStateEnum';
import type { DossierUpdateSchema } from '../models/DossierUpdateSchema';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DossiersService {

    /**
     * Dossier List
     * OAuth2 token does not match any internal user. It should store the `vat` parameter obtained with SPID.
     * @param skip
     * @param limit
     * @param state
     * @returns DossierSchema Successful Response
     * @throws ApiError
     */
    public static dossierListV1DossiersGet(
        skip?: number,
        limit: number = 100,
        state?: DossierStateEnum,
    ): CancelablePromise<Array<DossierSchema>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/dossiers/',
            query: {
                'skip': skip,
                'limit': limit,
                'state': state,
            },
            errors: {
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Dossier Create
     * @param requestBody
     * @returns DossierSchema Successful Response
     * @throws ApiError
     */
    public static dossierCreateV1DossiersPost(
        requestBody: DossierCreateSchema,
    ): CancelablePromise<DossierSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/dossiers/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Dossier Update
     * @param dossierId
     * @param requestBody
     * @returns DossierSchema Successful Response
     * @throws ApiError
     */
    public static dossierUpdateV1DossiersDossierIdPut(
        dossierId: string,
        requestBody: DossierUpdateSchema,
    ): CancelablePromise<DossierSchema> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/dossiers/{dossier_id}/',
            path: {
                'dossier_id': dossierId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

}
