
import Vue from "vue";
import { Alert } from "@/commons/types";
import Alerter from "@/components/Alerter.vue";

export default Vue.extend({
  name: "Login", // eslint-disable-line

  components: {
    Alerter,
  },

  data: () => ({
    alerts: [] as Alert[],
  }),
  computed: {
    maxCardWidth() {
      /*************************************
       * Return the right card length based on
       * current breakpoint.
       *************************************/
      switch (this.$vuetify.breakpoint.name) {
        case "xs" || "sm":
          return 400;
      }
      return 550;
    },
  },
  methods: {
    async doLogin() {
      /**********************
       * Do login
       **********************/
      try {
        window.open(
          "https://adspmarligureorientale-procedimenti-sua-spid.regione.liguria.it",
          "_blank"
        );
      } catch (err) {
        console.log(err);
        this.alerts.push({
          type: "error",
          text: "Errore in fase di login",
        });
      }
    },
  },
});
