
import Vue from "vue";

export default Vue.extend({
  name: "NavigationMenu",

  data: () => ({
    links: [
      {
        icon: "mdi-view-dashboard",
        text: "Dashboard",
        route: { name: "home" },
      },
      // {
      //   icon: "mdi-notebook-plus",
      //   text: "Pratiche aperte",
      //   route: { name: "openpractice" },
      // },
      {
        icon: "mdi-notebook-remove",
        text: "Storico pratiche",
        route: { name: "closedpractices" },
      },
    ],
  }),
});
